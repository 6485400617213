// eslint-disable-next-line import/named
import {
  // eslint-disable-next-line import/named
  dataTypes,
  seedPopup,
  seedTypes,
  betDataTypes,
  soundDataTypes,
  optionsType,
  serverSeedType,
} from './actionTypes';
import { CONSOLE_TYPES, SETTING_ICONS } from '../constants/game';

const {
  SET_BET_RESPONSE,
  SET_CURRENT_MY_BETS,
  RESET_BET_RESPONSE,
  FILL_BET_RESPONSE,
  UPDATE_BET_RESPONSE,
  SET_CASH_OUT_DATA,
  RESET_CASH_OUT_DATA,
  UPDATE_CASH_OUT_DATA,
} = betDataTypes;

const {
  SET_ROUND_INFO,
  SET_ROUNDS_HISTORY,
  SET_ODD,
  SET_GAME_STATE,
  SET_CHAT_HISTORY,
  SET_GAME_BETS,
  RESET_GAME_BETS,
  SET_LEADERS_LIST,
  SET_MY_BETS_LIST,
  SET_PREVIOUS_GAME,
  SET_BALANCE,
  SET_USER_INFO,
  UPDATE_CHAT_HISTORY,
  UPDATE_LIKES,
  SET_ERRORS_DATA,
  SET_NOTIFICATIONS_DATA,
  SET_ONLINE_USERS_COUNT,
  UPDATE_MY_BETS_LIST,
  SET_ACTIVE_LANGUAGE,
} = dataTypes;

const { SET_SOUND_DATA } = soundDataTypes;

const { SET_SERVER_SEED } = serverSeedType;

const { SET_OPTIONS } = optionsType;

const { SOUND, MUSIC, ROCKET, FULLSCREEN } = SETTING_ICONS;

const { SET_RANDOM_SEED, SET_IS_ACTIVE_MANUAL_SEED, SET_MANUAL_SEED } = seedTypes;

const { SET_SEED_POPUP_CHANGE } = seedPopup;

const { FIRST, SECOND } = CONSOLE_TYPES;

const betResponseInitialState = {
  [FIRST]: {},
  [SECOND]: {},
};

const cashOutResponseInitialState = {
  [FIRST]: {},
  [SECOND]: {},
};

const defaultOptions = {
  [SOUND]: !!+localStorage.getItem('sound'),
  [MUSIC]: !!+localStorage.getItem('music'),
  [ROCKET]: localStorage.getItem('rocket') !== '0',
  [FULLSCREEN]: false,
};

const roundInfoReducer = (state = '', action) => {
  switch (action.type) {
    case SET_ROUND_INFO:
      return action.value;
    default:
      return state;
  }
};

const roundsHistoryReducer = (state = [], action) => {
  switch (action.type) {
    case SET_ROUNDS_HISTORY:
      return action.value;
    default:
      return state;
  }
};

const oddReducer = (state = '', action) => {
  switch (action.type) {
    case SET_ODD:
      return action.value;
    default:
      return state;
  }
};

const gameStateReducer = (state = '', action) => {
  switch (action.type) {
    case SET_GAME_STATE:
      return action.value;
    default:
      return state;
  }
};

const seedPopupChangeReducer = (state = false, action) => {
  switch (action.type) {
    case SET_SEED_POPUP_CHANGE:
      return !state;
    default:
      return state;
  }
};

const randomSeedReducer = (state = '', action) => {
  switch (action.type) {
    case SET_RANDOM_SEED:
      return action.value;
    default:
      return state;
  }
};

const manualSeedReducer = (state = 'As322sd15d1sfc5sdz1c5d', action) => {
  switch (action.type) {
    case SET_MANUAL_SEED:
      return action.value;
    default:
      return state;
  }
};

const isActiveManualSeedReducer = (state = false, action) => {
  switch (action.type) {
    case SET_IS_ACTIVE_MANUAL_SEED:
      return action.value;
    default:
      return state;
  }
};

const betResponseReducer = (state = betResponseInitialState, action) => {
  switch (action.type) {
    case SET_BET_RESPONSE:
      const { c: consoleType } = action.value;
      return { ...state, [consoleType]: action.value };
    case FILL_BET_RESPONSE:
      return { ...betResponseInitialState, ...action.value };
    case RESET_BET_RESPONSE:
      return betResponseInitialState;
    default:
      return state;
  }
};

const gameBetsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_GAME_BETS:
      // eslint-disable-next-line no-case-declarations
      const allData = { ...state, ...action.value };
      return Object.values(allData).reduce((acc, el) => {
        if (el) {
          acc[el.id] = el;
        }
        return acc;
      }, {});
    case RESET_GAME_BETS:
      return action.value;
    default:
      return state;
  }
};

const leadersListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_LEADERS_LIST:
      if (action.value.length < 5) {
        let sortedLeaders = [...state, ...action.value].sort(
          (a, b) => b.cashout_amount - a.cashout_amount,
        );
        if (sortedLeaders.length > 30) {
          sortedLeaders = sortedLeaders.slice(0, 30);
        }
        return sortedLeaders;
      } else {
        return action.value.sort((a, b) => b.cashout_amount - a.cashout_amount);
      }
    default:
      return state;
  }
};

const myBetsListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_MY_BETS_LIST:
      if (action.value.length < 11) {
        return modifyTickets(state, action.value);
      }
      return action.value;
    case UPDATE_MY_BETS_LIST:
      return modifyTickets(state, action.value);
    default:
      return state;
  }
};

const currentMyBetsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CURRENT_MY_BETS:
      return action.value;
    default:
      return state;
  }
};

const previousGameReducer = (state = [], action) => {
  switch (action.type) {
    case SET_PREVIOUS_GAME:
      return action.value;
    default:
      return state;
  }
};

const chatHistoryReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CHAT_HISTORY:
      return [...state, ...action.value];
    case UPDATE_CHAT_HISTORY:
      return [...state, action.value];
    case UPDATE_LIKES:
      // eslint-disable-next-line no-case-declarations
      const likedObj = state.find((item) => item.id === action.value.id);
      likedObj.likes = action.value.likes;
      return [...state];
    default:
      return state;
  }
};

const balanceReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_BALANCE:
      return action.value;
    default:
      return state;
  }
};

const userInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return { ...state, ...action.value };
    default:
      return state;
  }
};

const cashOutDataReducer = (state = cashOutResponseInitialState, action) => {
  switch (action.type) {
    case SET_CASH_OUT_DATA:
      const { c: consoleType } = action.value;
      return { ...state, [consoleType]: action.value };
    case UPDATE_CASH_OUT_DATA:
      const { is_accepted: isAccepted, is_won: isWon, c: type } = action.value;
      return { ...state, [type]: {} };
    case RESET_CASH_OUT_DATA:
      return cashOutResponseInitialState;
    default:
      return state;
  }
};

const initialSoundData = {
  betButton: true,
  gameMusic: false,
  rocketCrashed: false,
  rocketStart: false,
  toggleSound: true,
};

const soundDataReducer = (state = initialSoundData, action) => {
  switch (action.type) {
    case SET_SOUND_DATA:
      return { ...state, ...action.value };
    default:
      return state;
  }
};

const errorsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ERRORS_DATA:
      return action.value;
    default:
      return state;
  }
};

const notificationsReducer = (
  state = { first: { active: false, content: null }, second: { active: false, content: null } },
  action,
) => {
  switch (action.type) {
    case SET_NOTIFICATIONS_DATA:
      return { ...state, ...action.value };
    default:
      return state;
  }
};

const activeOptionsReducer = (state = defaultOptions, action) => {
  switch (action.type) {
    case SET_OPTIONS:
      return action.value;
    default:
      return state;
  }
};

const serverSeedReducer = (state = '', action) => {
  switch (action.type) {
    case SET_SERVER_SEED:
      return action.value;
    default:
      return state;
  }
};

const usersOnlineCount = (state = { count: 1 }, action) => {
  switch (action.type) {
    case SET_ONLINE_USERS_COUNT:
      return action.value;
    default:
      return state;
  }
};

const activeLanguageReducer = (state = 'en', action) => {
  switch (action.type) {
    case SET_ACTIVE_LANGUAGE:
      return action.value;
    default:
      return state;
  }
};

export default {
  roundInfoReducer,
  roundsHistoryReducer,
  oddReducer,
  gameStateReducer,
  seedPopupChangeReducer,
  randomSeedReducer,
  isActiveManualSeedReducer,
  betResponseReducer,
  gameBetsReducer,
  leadersListReducer,
  myBetsListReducer,
  currentMyBetsReducer,
  chatHistoryReducer,
  previousGameReducer,
  balanceReducer,
  userInfoReducer,
  cashOutDataReducer,
  soundDataReducer,
  errorsReducer,
  notificationsReducer,
  activeOptionsReducer,
  serverSeedReducer,
  usersOnlineCount,
  manualSeedReducer,
  activeLanguageReducer,
};

function modifyTickets(state, actionValue) {
  let newTickets = [...state];
  actionValue.forEach((incomeTicket) => {
    newTickets.forEach((el, i) => {
      if (el.id === incomeTicket.id) {
        newTickets[i] = incomeTicket;
      }
    });
    if (!newTickets.find((el) => el.id === incomeTicket.id) && incomeTicket.id) {
      newTickets = [incomeTicket, ...newTickets];
    }
  });
  newTickets.sort((a, b) => b.round_id - a.round_id);
  return newTickets;
}
