import crown from './crown.svg';
import crownFirst from './crown-first.svg';
import crownSecond from './crown-second.svg';
import crownThird from './crown-third.svg';
import downArrow from './down-arrow.svg';
import group11 from './group11.png';
import layer from './layer.png';
import leaderShare from './leader-share.svg';
import leaderShareGreen from './leader-share-green.svg';
import leaderValidity from './leader-validity.svg';
import logo from './logo.svg';
import play from './play.svg';
import previous from './previous.svg';
import rectangle from './rectangle.png';
import rocket from './rocket.png';
import rocketShadow from './rocket-shadow.png';
import rocketShadow2 from './rocket-shadow2.png';
import coefficientButton from './coefficient-button.svg';
import error from './error.svg';
import group from './group.png';
import layer1 from './layer1.png';
import layer2 from './layer2.png';
import lock from './lock.svg';
import popupClose from './popup-close.svg';
import stars from './stars.png';
import starsZ1 from './stars-z1.png';
import starsZ2 from './stars-z2.png';
import avatarImg from './avatar-img.png';
import bet from './bet.png';
import betGray from './bet-gray.png';
import betLayer1 from './bet-layer1.svg';
import betLayer2 from './bet-layer2.svg';
import betLayer1Gray from './bet-layer1-gray.svg';
import betLayer2First from './bet-layer2-first.svg';
import consoleArrow from './console-arrow.svg';
import consoleButtonsBottom from './console-buttons-bottom.svg';
import consoleButtonsTop from './console-buttons-top.svg';
import consoleSpeed from './console-speed.svg';
import consoleSteps from './console-steps.svg';
import consoleStepsActive from './console-steps-active.svg';
import earth from './earth.png';
import earthShadow from './earth-shadow.png';
import plus from './plus.svg';
import rocketAnimation from './rocket-animation.png';
import rocketGarage from './rocket-garage.svg';
import betLayer4 from './bet-layer4.svg';
import demoLink from './demo-link.png';

const images = {
  avatarImg,
  bet,
  betGray,
  betLayer1,
  betLayer2,
  betLayer1Gray,
  betLayer2First,
  coefficientButton,
  consoleArrow,
  consoleButtonsBottom,
  consoleButtonsTop,
  consoleSpeed,
  consoleSteps,
  consoleStepsActive,
  crown,
  crownFirst,
  crownSecond,
  crownThird,
  downArrow,
  earth,
  earthShadow,
  error,
  group,
  group11,
  layer,
  layer1,
  layer2,
  leaderShare,
  leaderShareGreen,
  leaderValidity,
  lock,
  logo,
  play,
  plus,
  popupClose,
  previous,
  rectangle,
  rocket,
  rocketAnimation,
  rocketGarage,
  rocketShadow,
  rocketShadow2,
  stars,
  starsZ1,
  starsZ2,
  betLayer4,
  demoLink,
};

export default images;
