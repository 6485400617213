import React, { useEffect } from 'react';
import images from '../../../../assets/img';
import { useTranslation } from '../../../../context/LanguageProvider';

const { leaderValidity, group, layer1, layer2 } = images;

const ValidityPopup = ({ isActive, setIsActive, closePopup, outSideClick }) => {
  const handleClick = (event) => {
    outSideClick(event, 'validity');
  };

  useEffect(() => {
    setIsActive(true);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const t = useTranslation();

  return (
    <div className={`popup-validity ${isActive ? 'active' : ''}`}>
      <div className="popup-validity__header">
        <div className="popup-validity__header-left">
          <span className="popup-validity__question">{t('What is')}</span>
          <span className="validity__link-icon">
            <img src={leaderValidity} alt="Validity Icon" className="validity-icon-svg" />
          </span>
          <span className="validity__link-text">{t('Provable Fairness')}?</span>
        </div>
        <div className="popup-validity__close" onClick={closePopup}>
          <button className="popup-validity__close">
            <svg
              className="popup-validity__close-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 14 14">
              <path d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z" />
            </svg>
          </button>
        </div>
      </div>
      <div className="popup-validity__body">
        <div className="popup-validity__body-top">
          <p className="popup-validity__body-text">
            <b>{t('“Crazy Rocket”')} </b>{' '}
            {t(
              'is based on cryptographic technology called “Provable Fairness”. This technology guarantees 100% fairness of game result. With this technology, it’s impossible for any third party to interfere in game process.',
            )}
          </p>

          <h4 className="popup-validity__body-title">{t('How it works')}</h4>
          <p className="popup-validity__body-text">
            {t(
              'Result of each round (Game’s “Fly away” coefficient ) is not generated on our servers. It’s generated with help of round players and is fully transparent. This way, it’s impossible for anyone to manipulate game output. Also, anyone can check and confirm game fairness',
            )}
          </p>
          <h4 className="popup-validity__body-title">{t('More info')}</h4>
          <p className="popup-validity__body-text">
            {t(
              'Round result is generated from four independent participants of the round: game operator and first 3 betters of the round. Operator is generating server seed (random 16 symbols). Hashed version of this server seed is available publicly before round starts',
            )}
          </p>
          <p className="popup-validity__body-text">
            {t(
              'When round starts, game merges server seed with three client seeds. From merged symbols is generated SHA512 hash, and from this hash - game result.',
            )}
          </p>
        </div>
        <div className="popup-validity__body-center">
          <img src={group} alt="Validity-photo" className="popup-validity__body-img" />
        </div>
        <div className="popup-validity__check">
          <h4 className="popup-validity__body-title">{t('How to Check')}</h4>
          <div className="popup-validity__check-top">
            <div className="popup-validity__check-left">
              <img src={layer1} alt="Layer" className="popup-validity__check-img" />
            </div>
            <div className="popup-validity__check-right">
              <p className="popup-validity__body-text">
                {t(
                  'You can check fairness of each round from game history, by clicking on green icon.',
                )}
              </p>
              <p className="popup-validity__body-text">
                {t(
                  'In opened window, you will see server seed, 3 pair of players seeds, combined hash and round result.',
                )}
              </p>
            </div>
          </div>
          <div className="popup-validity__check-top">
            <div className="popup-validity__check-right">
              <div className="popup-validity__body-text">
                {t(
                  'Hashed version of next rounds server seed is available publicly in settings window (In user menu, check “Provably Fair Settings” and then “Next server seed SHA256”).',
                )}
                <p className="popup-validity__body-text">
                  {t(
                    'If you want to participate in round result generation, make sure you are between first 3 players who make bet in that round.',
                  )}
                </p>
              </div>
            </div>
            <div className="popup-validity__check-left">
              <img src={layer2} alt="Layer" className="popup-validity__check-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="popup-validity__footer">
        <div className="popup-validity__footer-top">
          {t('Correctness of Hash can be checked in any online calculator, for example')}
        </div>
        <div className="popup-validity__footer-bottom" style={{ cursor: 'pointer' }}>
          <a href="https://www.miniwebtool.com/sha512-hash-generator" target="_blank">
            www.miniwebtool.com/sha512-hash-generator
          </a>
        </div>
      </div>
    </div>
  );
};
export default ValidityPopup;
