import React from 'react';

// SVG IMAGES
export const MenuItems = {
  0: {
    section: 'bets',
    name: 'MY BETS',
    svg: (
      <svg
        className="menu__icon-svg menu__icon-bets"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19.45 12.3">
        <path
          className="cls-1"
          d="M15.82,6.15a3.64,3.64,0,0,1,3.63-3.63V0H0V2.52A3.64,3.64,0,0,1,3.63,6.15,3.64,3.64,0,0,1,0,9.78V12.3H19.45V9.78a3.64,3.64,0,0,1-3.63-3.63"
        />
      </svg>
    ),
  },
  1: {
    section: 'game',
    name: 'GAME',
    svg: (
      <svg
        className="menu__icon-svg menu__icon-game"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10.45 15.07">
        <path
          className="cls-1"
          d="M9.88,8.72l-7.39,6A1.53,1.53,0,0,1,0,13.55v-12A1.53,1.53,0,0,1,2.49.34l7.39,6a1.53,1.53,0,0,1,0,2.37"
        />
      </svg>
    ),
  },
  2: {
    section: 'leaders',
    name: 'LEADERS',
    svg: (
      <svg
        className="menu__icon-svg menu__icon-leaders"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20.22 16.92">
        <polygon
          className="cls-1"
          points="13.11 10.67 10.11 1.63 7.11 10.67 0 6.23 4.37 16.92 10.11 16.92 15.85 16.92 20.22 6.23 13.11 10.67"
        />
        <path className="cls-1" d="M12.47,2.36A2.36,2.36,0,1,1,10.11,0a2.36,2.36,0,0,1,2.36,2.36" />
      </svg>
    ),
  },
};

export const SVGIMG = {
  0: (
    <svg
      className="my-bets__head-id-svg"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 494.148 494.148"
      style={{ enableBackground: 'new 0 0 494.148 494.148' }}
      xmlSpace="preserve">
      <path d="M405.284,201.188L130.804,13.28C118.128,4.596,105.356,0,94.74,0C74.216,0,61.52,16.472,61.52,44.044v406.124c0,27.54,12.68,43.98,33.156,43.98c10.632,0,23.2-4.6,35.904-13.308l274.608-187.904c17.66-12.104,27.44-28.392,27.44-45.884C432.632,229.572,422.964,213.288,405.284,201.188z" />
    </svg>
  ),

  1: (
    <svg
      className="provably-fair__laptop"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42.9 28.17">
      <path d="M42.84,24a.21.21,0,0,0-.16-.08H.22A.21.21,0,0,0,.05,24a.21.21,0,0,0,0,.17s.65,4,5.62,4H37.28c5,0,5.61-3.93,5.61-4A.21.21,0,0,0,42.84,24ZM24.64,26.8H18.25V25.33h6.39Z" />
      <path d="M4.63,22.42H38.27a.88.88,0,0,0,.88-.88V.88A.88.88,0,0,0,38.27,0H4.63a.87.87,0,0,0-.88.88V21.54A.87.87,0,0,0,4.63,22.42ZM6.83,3.08H36.06V19.34H6.83Z" />
    </svg>
  ),
  2: (
    <svg
      className="settings__hash-copy-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.06 20.37">
      <path
        d="M13.17.25H4.56A1.66,1.66,0,0,0,2.9,1.91v1h-1A1.66,1.66,0,0,0,.25,4.56V18.47a1.65,1.65,0,0,0,1.66,1.65H12.5a1.65,1.65,0,0,0,1.66-1.65v-1h1a1.65,1.65,0,0,0,1.66-1.65V3.89a.3.3,0,0,0-.1-.23L13.4.35A.3.3,0,0,0,13.17.25Zm.33,18.22a1,1,0,0,1-1,1H1.91a1,1,0,0,1-1-1V4.56a1,1,0,0,1,1-1h8.28V5.22a1.65,1.65,0,0,0,1.65,1.65H13.5ZM10.85,5.22V4L13,6.21H11.84A1,1,0,0,1,10.85,5.22Zm5.3,10.6a1,1,0,0,1-1,1h-1V6.54a.29.29,0,0,0-.1-.23L10.75,3a.29.29,0,0,0-.23-.1h-7v-1a1,1,0,0,1,1-1h8.28V2.57a1.65,1.65,0,0,0,1.65,1.65h1.66ZM13.5,1.38l2.18,2.18H14.49a1,1,0,0,1-1-1Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeWidth="0.8"
      />
    </svg>
  ),
  3: (
    <svg
      className="settings__server-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35.92 35.92">
      <path d="M0,0V10.57H35.92V0ZM4.21,6.34V4.23H6.32V6.34Zm4.21,0V4.23h2.11V6.34Zm14.17,0V4.23h9.12V6.34Z" />
      <path d="M0,23.24H35.92V12.68H0Zm31.71-6.33V19H22.59v-2.1Zm-21.18,0V19H8.42v-2.1Zm-4.21,0V19H4.21v-2.1Z" />
      <path d="M0,35.92H35.92V25.35H0Zm31.71-6.34v2.11H22.59V29.58Zm-21.18,0v2.11H8.42V29.58Zm-4.21,0v2.11H4.21V29.58Z" />
    </svg>
  ),
  changeButton: (
    <svg
      className="popup-validity__close-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14">
      <path d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z" />
    </svg>
  ),
  chatButton: (
    <svg
      className="chat__button-svg"
      enableBackground="new 0 0 465.882 465.882"
      height="512"
      viewBox="0 0 465.882 465.882"
      width="512"
      xmlns="http://www.w3.org/2000/svg">
      <path d="m232.941 0c-128.649 0-232.941 91.254-232.941 203.824 0 27.129 6.06 53.017 17.059 76.689 26.899 57.893 23.683 125.862-12.218 178.647-3.011 4.427-4.841 6.722-4.841 6.722s117.187-21.41 178.37-64.091c17.534 3.689 35.743 5.856 54.571 5.856 128.65 0 232.941-91.256 232.941-203.824 0-112.569-104.291-203.823-232.941-203.823z" />
    </svg>
  ),
  chatArrowButton: (
    <svg
      className="chat__button-arrow-svg"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 491.996 491.996"
      xmlSpace="preserve">
      <path d="M484.132,124.986l-16.116-16.228c-5.072-5.068-11.82-7.86-19.032-7.86c-7.208,0-13.964,2.792-19.036,7.86l-183.84,183.848L62.056,108.554c-5.064-5.068-11.82-7.856-19.028-7.856s-13.968,2.788-19.036,7.856l-16.12,16.128c-10.496,10.488-10.496,27.572,0,38.06l219.136,219.924c5.064,5.064,11.812,8.632,19.084,8.632h0.084c7.212,0,13.96-3.572,19.024-8.632l218.932-219.328c5.072-5.064,7.856-12.016,7.864-19.224C491.996,136.902,489.204,130.046,484.132,124.986z" />
    </svg>
  ),
  chatLike: (
    <svg className="message__like-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.23 18.43">
      <title>like</title>
      <g className="cls-1">
        <path
          className="cls-2"
          d="M2,8a2,2,0,0,0-2,2v6.41a2,2,0,0,0,2,2H4.41a2,2,0,0,0,1.2-.4V8Z"
        />
        <path
          className="cls-2"
          d="M19.23,11a1.78,1.78,0,0,0-.52-1.26,1.94,1.94,0,0,0,.51-1.53,2.07,2.07,0,0,0-2.09-1.82H12.18a11.32,11.32,0,0,0,.64-3.2c0-1.74-1.48-3.21-2.4-3.21A2.53,2.53,0,0,0,9,.49.4.4,0,0,0,8.81.8V3.52l-2.3,5-.1.05v8.59a5.39,5.39,0,0,0,2,.47h7.36a1.85,1.85,0,0,0,1.82-1.4,1.82,1.82,0,0,0-.15-1.2,1.81,1.81,0,0,0,1-1.61,1.71,1.71,0,0,0-.19-.8A1.78,1.78,0,0,0,19.23,11Z"
        />
      </g>
    </svg>
  ),
  validity: (
    <svg
      className="leader__item-validity-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.99 44.4">
      <path
        d="M4,20a2.76,2.76,0,0,1,3.91-3.91l9.82,9.81L35,8.57V6.68A6.68,6.68,0,0,0,28.31,0H6.68A6.68,6.68,0,0,0,0,6.68V26.51a6.67,6.67,0,0,0,1.87,4.63L12.69,42.36a6.68,6.68,0,0,0,9.61,0L33.12,31.14A6.67,6.67,0,0,0,35,26.51V16.39l-17.3,17.3Z"
        fill="#8a9dad"
      />
    </svg>
  ),
  messageSubmit: (
    <svg
      className="message__submit-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.96 13.63">
      <path
        className="cls-1"
        d="M17.11,0H12.83a1.43,1.43,0,0,0-1.42,1.43h0a1.43,1.43,0,0,0,1.42,1.42H16A1.14,1.14,0,0,1,17.11,4V6a1.17,1.17,0,0,1-1.17,1.17H5.7L6.77,6.06a1.51,1.51,0,0,0,0-2.14h0a1.51,1.51,0,0,0-2.14,0L0,8.56l4.63,4.63a1.51,1.51,0,0,0,2.14,0h0a1.51,1.51,0,0,0,0-2.14L5.7,10H17.11A2.86,2.86,0,0,0,20,7.13V2.85A2.86,2.86,0,0,0,17.11,0Z"
      />
    </svg>
  ),
  messageSmile: (
    <svg
      className="message__write-bottom-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.6 17.6">
      <path
        className="cls-1"
        d="M12.89,7.56a.67.67,0,0,1-.68-.68.35.35,0,0,0-.35-.35.35.35,0,0,0-.34.35.69.69,0,0,1-1.38,0,1.72,1.72,0,0,1,3.44,0A.68.68,0,0,1,12.89,7.56ZM7.5,6.88a1.72,1.72,0,0,0-3.44,0,.69.69,0,0,0,1.37,0,.35.35,0,0,1,.35-.35.35.35,0,0,1,.34.35.69.69,0,0,0,1.38,0Zm5.86,9.46a.69.69,0,0,0-.72-1.18A7.5,7.5,0,1,1,15,12.92a.69.69,0,1,0,1.14.77A8.8,8.8,0,0,0,2.58,2.58,8.81,8.81,0,0,0,13.36,16.34ZM11.76,10a.69.69,0,0,0-.55.27,3.51,3.51,0,0,1-4.82,0A.65.65,0,0,0,5.85,10a.68.68,0,0,0-.69.68.69.69,0,0,0,.21.5A4.79,4.79,0,0,0,8.8,12.62a4.74,4.74,0,0,0,3.43-1.43.7.7,0,0,0,.22-.5A.68.68,0,0,0,11.76,10Z"
      />
    </svg>
  ),
  messageGIF: (
    <svg
      className="message__write-bottom-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.29 17.29">
      <g className="cls-1">
        <path
          className="cls-2"
          d="M5,7c.43,0,.53.2.55.53,0,.15.09.49.67.49.29,0,.68-.07.68-.65,0-1-.78-1.6-1.94-1.6A1.7,1.7,0,0,0,3.12,7.63v2A1.72,1.72,0,0,0,5,11.52,1.72,1.72,0,0,0,6.93,9.65V8.79a.49.49,0,0,0-.5-.5H5.27a.51.51,0,0,0-.49.57.53.53,0,0,0,.49.57h.31v.22c0,.57-.32.63-.56.63s-.55-.07-.55-.63v-2C4.47,7.08,4.77,7,5,7Z"
        />
        <path
          className="cls-2"
          d="M8.67,5.77c-.41,0-.68.2-.68.49V11c0,.3.28.5.68.5s.67-.21.67-.5V6.26C9.34,6,9.07,5.77,8.67,5.77Z"
        />
        <path
          className="cls-2"
          d="M11.14,5.77a.55.55,0,0,0-.61.5V11c0,.3.27.5.67.5s.67-.21.67-.5V9.19h.91a.51.51,0,0,0,.49-.56.52.52,0,0,0-.49-.58h-.91V7h1.8c.29,0,.49-.25.49-.62s-.2-.61-.49-.61Z"
        />
        <path
          className="cls-2"
          d="M8.64,0a8.65,8.65,0,1,0,8.65,8.64A8.66,8.66,0,0,0,8.64,0Zm0,15.94a7.3,7.3,0,1,1,7.3-7.3A7.31,7.31,0,0,1,8.64,15.94Z"
        />
      </g>
    </svg>
  ),
  nextRound: (
    <svg
      className="console-top__next-round-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 182.53 38.28">
      <path d="M0,25.49l2.21-1.24L9.13,31,6.56,21.8l2.23-1.25.82,3.23L14.3,28.1l-2.56-9.21,2.19-1.23,3.12,12.52-2.4,1.35-4-3.62,1.23,5.16-2.4,1.35Z" />
      <path d="M19.67,26.3l-.35-.77a2.19,2.19,0,0,1,.32-2.64,3.47,3.47,0,0,1,1.05-.72l2.41-1.11-.24-.53c-.26-.54-.6-.78-1-.7a3.13,3.13,0,0,0-.52.21c-.67.3-1.79.89-3.37,1.75l-.61-1.33a11.75,11.75,0,0,1,3.48-2.27q2.4-1.12,3.63.36a4.23,4.23,0,0,1,.55.91l2.63,5.71L25.9,26,25,25.27a2.28,2.28,0,0,1-.55.93A4.05,4.05,0,0,1,23,27.42a2.43,2.43,0,0,1-2.61-.23A2.36,2.36,0,0,1,19.67,26.3Zm2-1.3q.31.67.9.48l.22-.08a2.85,2.85,0,0,0,.8-.56,4.47,4.47,0,0,0,.59-.66l.2-.3-.69-1.49-1.92,1.07a.81.81,0,0,0-.28,1.14Z" />
      <path d="M24.73,15.17l-.57-1.38a.42.42,0,0,1,.21-.56L26,12.54a.41.41,0,0,1,.52.27l.57,1.38a.36.36,0,0,1-.2.51l-1.65.68A.42.42,0,0,1,24.73,15.17Zm4,9.56-3.44-8.35,2.2-.91L31,23.82Z" />
      <path d="M29.26,16.69l-.46-1.24,1.19-1L29.47,12l1.87-.69.88,2.35,1.89-.71.64,1.7-1.89.71,1.27,3.39c.32.85.68,1.32,1.09,1.39l.26,0,1.19-.07.51,1.37-1.94.73c-1.38.49-2.44-.26-3.2-2.25l-1.41-3.74Z" />
      <path d="M34.83,11.29l-.48-1.41a.41.41,0,0,1,.24-.55l1.69-.58a.41.41,0,0,1,.5.3l.48,1.42A.36.36,0,0,1,37,11l-1.69.57A.41.41,0,0,1,34.83,11.29Zm3.41,9.8-2.9-8.55,2.25-.76,2.9,8.54Z" />
      <path d="M41.82,19.85l-2.54-8.67,1.82-.53.73.79a6.44,6.44,0,0,1,1.32-1.37,3.77,3.77,0,0,1,1.11-.51q2.58-.72,3.48,2.28l1.69,5.77-2.28.67-1.7-5.81c-.25-.76-.69-1.06-1.31-.89a3.19,3.19,0,0,0-1.67,1.13l-.2.24,1.83,6.23Z" />
      <path d="M50.06,15.65a1.51,1.51,0,0,1,.53-1.71l.14-.1a2.63,2.63,0,0,1-1.48-2c0-.07,0-.14,0-.2-.47-2.08.31-3.36,2.32-3.84l5.35-1.23L57.21,8l-.91.53a3.75,3.75,0,0,1,.6,1.2,3.17,3.17,0,0,1,.12.44,2.69,2.69,0,0,1-.89,3,4,4,0,0,1-1.44.63l-2,.47c-.43.12-.62.3-.57.53s.33.39.76.29l2.7-.62a2.32,2.32,0,0,1,2.93,1.2,3.94,3.94,0,0,1,.27.82l.22.95a2.28,2.28,0,0,1-1.29,2.89,4.23,4.23,0,0,1-.87.29l-2.49.57a2.57,2.57,0,0,1-2.76-.7A2.88,2.88,0,0,1,51,19.24l-.26-1.11.59-1a1.77,1.77,0,0,1-1.18-1.26Zm1.47-4.56c.21.9.67,1.27,1.37,1.12l.93-.22c.74-.18,1-.72.81-1.61-.18-.68-.49-1-.92-1.06a3.28,3.28,0,0,0-.52,0l-.87.21a1,1,0,0,0-.87,1.24C51.48,10.9,51.5,11,51.53,11.09Zm1.72,7.33c.12.56.41.82.86.8l.41-.06,1.36-.31c.55-.13.8-.4.77-.82,0-.11,0-.22,0-.33l-.15-.64a.76.76,0,0,0-1-.63h-.07l-1.79.42-.71.11Z" />
      <path d="M63.58,7.26l-.21-1.51,1.35-.48L64.49,3.6C64.26,2,64.88,1,66.36.67l.4-.07a5.88,5.88,0,0,1,2.31.09l.2,1.39-1.62.21c-.48.06-.75.26-.79.6a4.51,4.51,0,0,0,.06.93l.16,1.11,2-.28.25,1.8-2,.28,1,7.14L66,14.2,65,7.07Z" />
      <path d="M71.23,9c-.17-1.91.06-3.22.69-3.94a4.34,4.34,0,0,1,3-1.13c1.59-.14,2.71.12,3.36.79a6,6,0,0,1,1.16,3.55c.15,1.76-.07,3-.67,3.68a4.51,4.51,0,0,1-3,1.14c-1.68.15-2.85-.17-3.51-1A5.78,5.78,0,0,1,71.23,9Zm2.37-.21c.11,1.24.36,2,.74,2.23a2.08,2.08,0,0,0,1.21.15,1.89,1.89,0,0,0,1.29-.43,3.74,3.74,0,0,0,.21-2.26,4.53,4.53,0,0,0-.6-2.4,1.93,1.93,0,0,0-1.37-.26q-1,.09-1.29.51A4.54,4.54,0,0,0,73.6,8.83Z" />
      <path d="M81.08,12.63l-.33-9,2-.07.41,1.13a3.75,3.75,0,0,1,2.54-1.32,3,3,0,0,1,1,.07l.08,2.29c-.33,0-.77,0-1.31,0a2.68,2.68,0,0,0-1.92.68,4,4,0,0,0-.32.4l.21,5.76Z" />
      <path d="M93.66,12.42,94.38,0l2.41.14,4.45,8.31.47-8,2.37.14L103.36,13,101,12.85,96.51,4.62l-.46,7.94Z" />
      <path d="M105.14,8.61c.23-1.87.69-3.07,1.37-3.6a3.89,3.89,0,0,1,2.8-.46l.3,0a5.77,5.77,0,0,1,2.7.78c.62.48.87,1.4.72,2.76,0,.06,0,.13,0,.18a2,2,0,0,1-2.28,2l-.5,0-2.85-.35c-.1.84,0,1.38.44,1.62a3.41,3.41,0,0,0,1.24.31,22.51,22.51,0,0,0,3.32.22l-.2,1.6a15.27,15.27,0,0,1-4.65-.09A2.48,2.48,0,0,1,105.24,12,8.31,8.31,0,0,1,105.14,8.61Zm2.45-.4,2.25.28c.48,0,.76-.16.82-.59.08-.62,0-1-.14-1.18a1.93,1.93,0,0,0-1-.31,2.26,2.26,0,0,0-1.33.09A2.52,2.52,0,0,0,107.59,8.21Z" />
      <path d="M112.94,14.16l3.89-3.88-2.27-5,2.84.52,1.14,3,2.16-2.35,2.82.51-3.93,3.91,2.17,4.95-2.69-.49-1.17-2.93-2.12,2.32Z" />
      <path d="M124,8.89l.3-1.29,1.54-.14,1-2.36,1.93.46L128.11,8l2,.46-.42,1.77-2-.46-.83,3.52c-.21.88-.17,1.47.12,1.76l.21.15,1,.61-.34,1.43-2-.48q-2.13-.54-1.41-3.65l.92-3.89Z" />
      <path d="M134.26,19l3.91-11.81,5.16,1.7c2,.66,2.72,2.08,2.16,4.24l-.15.51c-.6,1.82-1.46,2.77-2.59,2.85a3.05,3.05,0,0,1-.45,0l.71,5.39L140.46,21,140,15.91,138.07,15l-1.55,4.7Zm4.41-5.72,2.68.89c.51.16.88.12,1.12-.14a3,3,0,0,0,.6-1.14c.32-1,.22-1.6-.29-1.9l-.32-.17-2.68-.88Z" />
      <path d="M145.3,18.07c.71-1.78,1.51-2.84,2.4-3.2a4.37,4.37,0,0,1,3.17.34c1.49.6,2.37,1.34,2.64,2.24a5.9,5.9,0,0,1-.58,3.68c-.66,1.64-1.41,2.64-2.25,3a4.5,4.5,0,0,1-3.23-.36c-1.57-.63-2.47-1.44-2.7-2.44A5.73,5.73,0,0,1,145.3,18.07Zm2.2.89c-.46,1.15-.58,1.93-.34,2.33a2,2,0,0,0,1,.68,1.87,1.87,0,0,0,1.35.19,3.8,3.8,0,0,0,1.2-1.91,4.57,4.57,0,0,0,.55-2.42,1.9,1.9,0,0,0-1.1-.85c-.64-.25-1.09-.3-1.38-.13A4.5,4.5,0,0,0,147.5,19Z" />
      <path d="M153.6,22.91l2.56-5.43,2.15,1L155.73,24c-.33.73-.2,1.24.39,1.52a3.28,3.28,0,0,0,2,.28l.31-.06,2.77-5.86,2.15,1L159.49,29l-1.74-.82V27.16a5.17,5.17,0,0,0-.53.05,4.77,4.77,0,0,1-1.88,0,4.44,4.44,0,0,1-.66-.25Q152.27,25.76,153.6,22.91Z" />
      <path d="M160.71,29.66,165,21.73l1.67.91-.05,1.08a6.87,6.87,0,0,1,1.9,0,4.32,4.32,0,0,1,1.15.42q2.34,1.32.85,4.07l-2.88,5.29-2.09-1.14,2.9-5.32c.37-.71.27-1.23-.29-1.55a3.21,3.21,0,0,0-2-.38l-.31,0-3.11,5.7Z" />
      <path d="M171,30.21a6.7,6.7,0,0,1,2.67-2.82,2.63,2.63,0,0,1,2.31.4,6.61,6.61,0,0,1,2,1.9.61.61,0,0,1,0,.06l2.53-4.05,2,1.26-7.08,11.32-1.67-1.05.14-1a4.58,4.58,0,0,1-2.45-.37,4,4,0,0,1-.41-.22c-1.3-.81-1.61-2-1-3.73A11.81,11.81,0,0,1,171,30.21Zm2.07,1.19c-.81,1.29-1,2.16-.66,2.62a1,1,0,0,0,.28.23,3.88,3.88,0,0,0,2.07.66l2.37-3.79a6,6,0,0,0-1.6-1.41,1,1,0,0,0-1.2.1A6.79,6.79,0,0,0,173.06,31.4Z" />
    </svg>
  ),
  nextRoundSw: (
    <svg
      className="st0 st1 st2 st3 st4 st5 st6 st7"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 182.5 38.3"
      style={{ enableBackground: 'new 0 0 182.5 38.3' }}
      x="0px"
      y="0px">
      <g>
        <g className="st0">
          <path
            d="M3.6,29.9l-0.7-0.8c-0.3-0.3-0.6-0.7-0.7-1c-0.2-0.3-0.3-0.7-0.3-1c0-0.3,0-0.6,0.1-0.9s0.3-0.6,0.4-0.9
			c0.2-0.3,0.4-0.5,0.7-0.8c0.6-0.6,1.3-1.2,2-1.7c0.7-0.6,1.3-0.9,1.8-1.2l1.3,1.3c-1,0.8-2,1.7-3.1,2.7c-0.4,0.4-0.7,0.7-0.8,1
			c-0.1,0.3,0.1,0.6,0.4,1l0.4,0.4c0.3,0.3,0.6,0.5,0.9,0.5c0.2,0,0.5-0.2,0.9-0.5l1.4-1.3c0.2-0.2,0.4-0.4,0.7-0.5
			c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.1,0.6-0.1s0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.3,0.2,0.5,0.3
			c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.4l0.6,0.6c0.4,0.4,0.6,0.8,0.8,1.2c0.2,0.4,0.3,0.7,0.3,1.1c0,0.3,0,0.6-0.2,0.9
			c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.2-0.4,0.5-0.7,0.7c-1.5,1.4-2.7,2.4-3.8,2.9L8,34c1-0.8,2.1-1.7,3.2-2.8
			c0.2-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.1-0.3,0-0.5c0-0.2-0.1-0.3-0.3-0.5l-0.5-0.5
			c-0.3-0.3-0.5-0.4-0.8-0.5c-0.2,0-0.5,0.1-0.8,0.4l-1.4,1.3C6.6,31.5,5.1,31.5,3.6,29.9z"
          />
        </g>
        <g className="st0">
          <path
            className="st1"
            d="M3.6,29.9l-0.7-0.8c-0.3-0.3-0.6-0.7-0.7-1c-0.2-0.3-0.3-0.7-0.3-1c0-0.3,0-0.6,0.1-0.9s0.3-0.6,0.4-0.9
			c0.2-0.3,0.4-0.5,0.7-0.8c0.6-0.6,1.3-1.2,2-1.7c0.7-0.6,1.3-0.9,1.8-1.2l1.3,1.3c-1,0.8-2,1.7-3.1,2.7c-0.4,0.4-0.7,0.7-0.8,1
			c-0.1,0.3,0.1,0.6,0.4,1l0.4,0.4c0.3,0.3,0.6,0.5,0.9,0.5c0.2,0,0.5-0.2,0.9-0.5l1.4-1.3c0.2-0.2,0.4-0.4,0.7-0.5
			c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.1,0.6-0.1s0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.3,0.2,0.5,0.3
			c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.4l0.6,0.6c0.4,0.4,0.6,0.8,0.8,1.2c0.2,0.4,0.3,0.7,0.3,1.1c0,0.3,0,0.6-0.2,0.9
			c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.2-0.4,0.5-0.7,0.7c-1.5,1.4-2.7,2.4-3.8,2.9L8,34c1-0.8,2.1-1.7,3.2-2.8
			c0.2-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.1-0.3,0-0.5c0-0.2-0.1-0.3-0.3-0.5l-0.5-0.5
			c-0.3-0.3-0.5-0.4-0.8-0.5c-0.2,0-0.5,0.1-0.8,0.4l-1.4,1.3C6.6,31.5,5.1,31.5,3.6,29.9z"
          />
        </g>
        <g className="st0">
          <path
            d="M14.5,27.2l-3-5.1l2-1.2l3,5.2c0.4,0.7,0.9,0.9,1.5,0.5c0.3-0.2,0.5-0.3,0.7-0.5c0.2-0.2,0.3-0.4,0.5-0.5
			c0.1-0.2,0.2-0.4,0.4-0.7l-3.2-5.5l2-1.2l4.4,7.7l-1.6,0.9l-0.9-0.6c-0.1,0.1-0.1,0.3-0.3,0.5c-0.1,0.2-0.2,0.4-0.3,0.5
			c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.5s-0.4,0.3-0.6,0.4C16.8,29.5,15.5,29,14.5,27.2z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M14.5,27.2l-3-5.1l2-1.2l3,5.2c0.4,0.7,0.9,0.9,1.5,0.5c0.3-0.2,0.5-0.3,0.7-0.5c0.2-0.2,0.3-0.4,0.5-0.5
			c0.1-0.2,0.2-0.4,0.4-0.7l-3.2-5.5l2-1.2l4.4,7.7l-1.6,0.9l-0.9-0.6c-0.1,0.1-0.1,0.3-0.3,0.5c-0.1,0.2-0.2,0.4-0.3,0.5
			c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.5s-0.4,0.3-0.6,0.4C16.8,29.5,15.5,29,14.5,27.2z"
          />
        </g>
        <g className="st0">
          <path
            d="M23.8,25.5l-5-12.2l2.2-0.9l1.8,4.4c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.4
			c0.2-0.1,0.4-0.2,0.6-0.3c0.4-0.2,0.9-0.3,1.2-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.2,0.6,0.4,0.8,0.7c0.3,0.3,0.5,0.7,0.7,1.1
			s0.4,0.9,0.6,1.4c0.5,1.3,0.8,2.4,0.7,3.3s-0.7,1.5-1.6,1.9c-0.4,0.2-0.9,0.3-1.5,0.3c-0.5,0-1-0.1-1.4-0.2l0,1L23.8,25.5z
			 M25,22.3c0.7,0.1,1.4,0,2.1-0.3c0.2-0.1,0.4-0.2,0.5-0.5s0.1-0.5,0-0.9c-0.1-0.4-0.2-0.9-0.5-1.5c-0.3-0.7-0.5-1.2-0.8-1.5
			c-0.2-0.3-0.5-0.5-0.7-0.6c-0.2-0.1-0.4,0-0.7,0.1c-0.4,0.2-0.7,0.3-1,0.5c-0.3,0.2-0.6,0.4-0.8,0.6L25,22.3z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M23.8,25.5l-5-12.2l2.2-0.9l1.8,4.4c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.4-0.3,0.6-0.4
			c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.2,0.6-0.3c0.4-0.2,0.9-0.3,1.2-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.2,0.6,0.4,0.8,0.7
			c0.3,0.3,0.5,0.7,0.7,1.1s0.4,0.9,0.6,1.4c0.5,1.3,0.8,2.4,0.7,3.3s-0.7,1.5-1.6,1.9c-0.4,0.2-0.9,0.3-1.5,0.3
			c-0.5,0-1-0.1-1.4-0.2l0,1L23.8,25.5z M25,22.3c0.7,0.1,1.4,0,2.1-0.3c0.2-0.1,0.4-0.2,0.5-0.5s0.1-0.5,0-0.9
			c-0.1-0.4-0.2-0.9-0.5-1.5c-0.3-0.7-0.5-1.2-0.8-1.5c-0.2-0.3-0.5-0.5-0.7-0.6c-0.2-0.1-0.4,0-0.7,0.1c-0.4,0.2-0.7,0.3-1,0.5
			c-0.3,0.2-0.6,0.4-0.8,0.6L25,22.3z"
          />
        </g>
        <g className="st0">
          <path
            d="M28.9,12.5l-0.5-1.4c-0.1-0.3,0-0.5,0.2-0.5l1.7-0.6c0.2-0.1,0.4,0,0.5,0.3l0.5,1.4c0,0.1,0,0.2,0,0.3s-0.1,0.1-0.2,0.2
			l-1.7,0.6c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1C28.9,12.6,28.9,12.5,28.9,12.5z M32.2,22.1l-2.8-8.4l2.2-0.7l2.8,8.4
			L32.2,22.1z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M28.9,12.5l-0.5-1.4c-0.1-0.3,0-0.5,0.2-0.5l1.7-0.6c0.2-0.1,0.4,0,0.5,0.3l0.5,1.4c0,0.1,0,0.2,0,0.3
			s-0.1,0.1-0.2,0.2l-1.7,0.6c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1C28.9,12.6,28.9,12.5,28.9,12.5z M32.2,22.1l-2.8-8.4l2.2-0.7
			l2.8,8.4L32.2,22.1z"
          />
        </g>
        <g className="st0">
          <path
            d="M35.7,20.9l-2.5-8.6l1.9-0.5l0.7,1c0.6-1,1.3-1.6,2.1-1.9c0.5-0.1,0.8-0.2,1-0.2l0.6,2.2c-0.3,0.1-0.7,0.2-1.3,0.3
			c-0.5,0.1-0.9,0.3-1.2,0.6c-0.3,0.2-0.5,0.6-0.7,1l1.6,5.5L35.7,20.9z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M35.7,20.9l-2.5-8.6l1.9-0.5l0.7,1c0.6-1,1.3-1.6,2.1-1.9c0.5-0.1,0.8-0.2,1-0.2l0.6,2.2
			c-0.3,0.1-0.7,0.2-1.3,0.3c-0.5,0.1-0.9,0.3-1.2,0.6c-0.3,0.2-0.5,0.6-0.7,1l1.6,5.5L35.7,20.9z"
          />
        </g>
        <g className="st0">
          <path
            d="M39.8,9.2l-0.4-1.4c-0.1-0.3,0-0.5,0.3-0.5l1.7-0.4c0.2-0.1,0.4,0.1,0.5,0.3l0.4,1.4c0,0.1,0,0.2,0,0.3
			C42.2,9,42.1,9,42,9.1l-1.7,0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1C39.8,9.3,39.8,9.3,39.8,9.2z M42.3,19.1l-2.1-8.6
			l2.3-0.6l2.1,8.6L42.3,19.1z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M39.8,9.2l-0.4-1.4c-0.1-0.3,0-0.5,0.3-0.5l1.7-0.4c0.2-0.1,0.4,0.1,0.5,0.3l0.4,1.4c0,0.1,0,0.2,0,0.3
			C42.2,9,42.1,9,42,9.1l-1.7,0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1C39.8,9.3,39.8,9.3,39.8,9.2z M42.3,19.1l-2.1-8.6
			l2.3-0.6l2.1,8.6L42.3,19.1z"
          />
        </g>
        <g className="st0">
          <path
            d="M51.2,17.1l-1.6-8.8L51.6,8l0.6,1c0.7-0.9,1.4-1.5,2.3-1.6c0.5-0.1,0.8-0.1,1-0.1l0.4,2.2c-0.3,0-0.7,0.1-1.3,0.2
			c-0.5,0.1-0.9,0.2-1.3,0.4c-0.3,0.2-0.6,0.5-0.8,0.9l1,5.6L51.2,17.1z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M51.2,17.1l-1.6-8.8L51.6,8l0.6,1c0.7-0.9,1.4-1.5,2.3-1.6c0.5-0.1,0.8-0.1,1-0.1l0.4,2.2
			c-0.3,0-0.7,0.1-1.3,0.2c-0.5,0.1-0.9,0.2-1.3,0.4c-0.3,0.2-0.6,0.5-0.8,0.9l1,5.6L51.2,17.1z"
          />
        </g>
        <g className="st0">
          <path
            d="M57.4,13.7l-0.1-0.8c0-0.3,0-0.7,0-0.9c0.1-0.3,0.2-0.6,0.3-0.8c0.2-0.3,0.4-0.5,0.7-0.6c0.3-0.2,0.7-0.3,1.2-0.4
			C60,10,60.4,10,60.8,9.9c0.4-0.1,0.9-0.1,1.3-0.2c0-0.4-0.1-0.6-0.1-0.6c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.2-0.2-0.3-0.3
			s-0.2-0.1-0.4-0.1c-0.1,0-0.3,0-0.5,0C60,8.4,58.8,8.6,57,9l-0.2-1.4c0.9-0.5,2.2-0.9,3.9-1.1c1-0.1,1.8,0,2.4,0.3
			c0.6,0.4,1,1,1.1,2.1l0.8,6.1l-1.8,0.3l-0.6-0.9c0,0.1-0.2,0.2-0.4,0.4c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.4-1.2,0.4
			c-0.8,0.1-1.4-0.1-1.9-0.5C57.8,14.8,57.5,14.3,57.4,13.7z M59.7,13.1c0,0.3,0.1,0.5,0.3,0.6s0.4,0.1,0.6,0.1
			c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2s0.2-0.1,0.2-0.2
			c0.1,0,0.1-0.1,0.2-0.2l-0.2-1.6l-2.1,0.5c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
			c0,0.1,0,0.2,0,0.3L59.7,13.1z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M57.4,13.7l-0.1-0.8c0-0.3,0-0.7,0-0.9c0.1-0.3,0.2-0.6,0.3-0.8c0.2-0.3,0.4-0.5,0.7-0.6
			c0.3-0.2,0.7-0.3,1.2-0.4C60,10,60.4,10,60.8,9.9c0.4-0.1,0.9-0.1,1.3-0.2c0-0.4-0.1-0.6-0.1-0.6c0-0.2-0.1-0.4-0.2-0.6
			c-0.1-0.1-0.2-0.2-0.3-0.3s-0.2-0.1-0.4-0.1c-0.1,0-0.3,0-0.5,0C60,8.4,58.8,8.6,57,9l-0.2-1.4c0.9-0.5,2.2-0.9,3.9-1.1
			c1-0.1,1.8,0,2.4,0.3c0.6,0.4,1,1,1.1,2.1l0.8,6.1l-1.8,0.3l-0.6-0.9c0,0.1-0.2,0.2-0.4,0.4c-0.2,0.2-0.5,0.4-0.9,0.6
			c-0.4,0.2-0.8,0.4-1.2,0.4c-0.8,0.1-1.4-0.1-1.9-0.5C57.8,14.8,57.5,14.3,57.4,13.7z M59.7,13.1c0,0.3,0.1,0.5,0.3,0.6
			s0.4,0.1,0.6,0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2s0.2-0.1,0.2-0.2
			c0.1,0,0.1-0.1,0.2-0.2l-0.2-1.6l-2.1,0.5c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
			c0,0.1,0,0.2,0,0.3L59.7,13.1z"
          />
        </g>
        <g className="st0">
          <path
            d="M66,11.8l-0.5-5.9l2.3-0.2l0.5,6c0.1,0.8,0.4,1.2,1.1,1.1c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.5-0.2,0.6-0.3s0.4-0.3,0.6-0.5
			L71,5.4l2.3-0.2l0.8,8.9l-1.9,0.2l-0.5-0.9c-0.1,0.1-0.2,0.2-0.5,0.3s-0.4,0.3-0.5,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
			c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0.1-0.4,0.1-0.7,0.1C67.2,14.9,66.2,13.9,66,11.8z"
          />
        </g>
        <g className="st0">
          <path
            className="st3"
            d="M66,11.8l-0.5-5.9l2.3-0.2l0.5,6c0.1,0.8,0.4,1.2,1.1,1.1c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.5-0.2,0.6-0.3
			s0.4-0.3,0.6-0.5L71,5.4l2.3-0.2l0.8,8.9l-1.9,0.2l-0.5-0.9c-0.1,0.1-0.2,0.2-0.5,0.3s-0.4,0.3-0.5,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
			c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0.1-0.4,0.1-0.7,0.1C67.2,14.9,66.2,13.9,66,11.8z"
          />
        </g>
        <g className="st0">
          <path
            d="M75.5,13.9L75,5.1L76.9,5l0.5,0.9c0.3-0.2,0.5-0.4,0.6-0.5c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.2,0.5-0.3,0.8-0.3
			c0.3-0.1,0.6-0.1,0.9-0.1c1.8-0.1,2.7,0.9,2.8,3l0.3,5.9L81,13.6l-0.3-6c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.4-0.3-0.7-0.2
			c-0.3,0-0.6,0.1-0.8,0.1c-0.2,0.1-0.4,0.2-0.6,0.3s-0.4,0.3-0.6,0.5l0.4,6.4L75.5,13.9z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M75.5,13.9L75,5.1L76.9,5l0.5,0.9c0.3-0.2,0.5-0.4,0.6-0.5c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.2,0.5-0.3,0.8-0.3
			c0.3-0.1,0.6-0.1,0.9-0.1c1.8-0.1,2.7,0.9,2.8,3l0.3,5.9L81,13.6l-0.3-6c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.4-0.3-0.7-0.2
			c-0.3,0-0.6,0.1-0.8,0.1c-0.2,0.1-0.4,0.2-0.6,0.3s-0.4,0.3-0.6,0.5l0.4,6.4L75.5,13.9z"
          />
        </g>
        <g className="st0">
          <path
            d="M84.4,9.1c0-0.6,0-1.1,0-1.6c0-0.4,0.1-0.9,0.2-1.3s0.3-0.7,0.5-1C85.4,5,85.6,4.8,86,4.6c0.3-0.2,0.8-0.2,1.2-0.2
			c0.3,0,0.5,0,0.9,0.1c0.3,0,0.6,0.1,1,0.2c0.3,0.1,0.6,0.2,0.9,0.3l-0.1-4.7l2.3,0l0.2,13.2l-1.9,0l-0.4-0.9
			c-0.3,0.3-0.7,0.5-1.2,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.5-0.1c-0.2-0.1-0.3-0.1-0.5-0.2
			s-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.1-0.4-0.1-0.7
			c0-0.2-0.1-0.5-0.1-0.7S84.4,9.3,84.4,9.1z M86.7,8.9c0,0.9,0.1,1.6,0.3,2c0.2,0.4,0.5,0.6,0.9,0.6c0.4,0,0.8-0.1,1.1-0.2
			c0.4-0.1,0.7-0.2,0.9-0.4l-0.1-4.4c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0
			c-0.2,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2C87,6.6,87,6.8,86.9,7c-0.1,0.2-0.1,0.5-0.1,0.8C86.7,8.1,86.7,8.5,86.7,8.9z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M84.4,9.1c0-0.6,0-1.1,0-1.6c0-0.4,0.1-0.9,0.2-1.3s0.3-0.7,0.5-1C85.4,5,85.6,4.8,86,4.6
			c0.3-0.2,0.8-0.2,1.2-0.2c0.3,0,0.5,0,0.9,0.1c0.3,0,0.6,0.1,1,0.2c0.3,0.1,0.6,0.2,0.9,0.3l-0.1-4.7l2.3,0l0.2,13.2l-1.9,0
			l-0.4-0.9c-0.3,0.3-0.7,0.5-1.2,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.5-0.1c-0.2-0.1-0.3-0.1-0.5-0.2
			s-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.1-0.4-0.1-0.7
			c0-0.2-0.1-0.5-0.1-0.7S84.4,9.3,84.4,9.1z M86.7,8.9c0,0.9,0.1,1.6,0.3,2c0.2,0.4,0.5,0.6,0.9,0.6c0.4,0,0.8-0.1,1.1-0.2
			c0.4-0.1,0.7-0.2,0.9-0.4l-0.1-4.4c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0
			c-0.2,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2C87,6.6,87,6.8,86.9,7c-0.1,0.2-0.1,0.5-0.1,0.8C86.7,8.1,86.7,8.5,86.7,8.9z"
          />
        </g>
        <g className="st0">
          <path
            d="M93.8,13.4l0.1-8.9l2.3,0l-0.1,8.9L93.8,13.4z M93.8,3.1l0-1.5c0-0.3,0.1-0.4,0.4-0.4l1.8,0c0.2,0,0.4,0.1,0.4,0.4l0,1.5
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1l-1.8,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.1S93.8,3.2,93.8,3.1z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M93.8,13.4l0.1-8.9l2.3,0l-0.1,8.9L93.8,13.4z M93.8,3.1l0-1.5c0-0.3,0.1-0.4,0.4-0.4l1.8,0
			c0.2,0,0.4,0.1,0.4,0.4l0,1.5c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1l-1.8,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.1
			S93.8,3.2,93.8,3.1z"
          />
        </g>
        <g className="st0">
          <path
            d="M102.9,13.6l0.4-8.9l2.3,0.1l-0.4,8.9L102.9,13.6z M103.3,3.4l0.1-1.5c0-0.3,0.2-0.4,0.4-0.4l1.8,0.1
			c0.2,0,0.4,0.2,0.3,0.5l-0.1,1.5c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1l-1.8-0.1c-0.1,0-0.1,0-0.2-0.1
			c-0.1,0-0.1-0.1-0.1-0.1C103.3,3.5,103.3,3.4,103.3,3.4z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M102.9,13.6l0.4-8.9l2.3,0.1l-0.4,8.9L102.9,13.6z M103.3,3.4l0.1-1.5c0-0.3,0.2-0.4,0.4-0.4l1.8,0.1
			c0.2,0,0.4,0.2,0.3,0.5l-0.1,1.5c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1l-1.8-0.1c-0.1,0-0.1,0-0.2-0.1
			c-0.1,0-0.1-0.1-0.1-0.1C103.3,3.5,103.3,3.4,103.3,3.4z"
          />
        </g>
        <g className="st0">
          <path
            d="M106.7,13.7l0.7-8.9l1.9,0.1l0.4,1c0.3-0.2,0.5-0.3,0.6-0.4c0.1-0.1,0.3-0.2,0.6-0.3c0.3-0.1,0.5-0.2,0.8-0.2
			c0.3,0,0.6,0,0.9,0c1.8,0.1,2.6,1.2,2.4,3.3l-0.4,5.9l-2.3-0.2l0.4-6c0-0.4,0-0.7-0.2-0.9c-0.2-0.2-0.4-0.3-0.7-0.3
			c-0.3,0-0.6,0-0.8,0c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.7,0.4l-0.5,6.4L106.7,13.7z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M106.7,13.7l0.7-8.9l1.9,0.1l0.4,1c0.3-0.2,0.5-0.3,0.6-0.4c0.1-0.1,0.3-0.2,0.6-0.3c0.3-0.1,0.5-0.2,0.8-0.2
			c0.3,0,0.6,0,0.9,0c1.8,0.1,2.6,1.2,2.4,3.3l-0.4,5.9l-2.3-0.2l0.4-6c0-0.4,0-0.7-0.2-0.9c-0.2-0.2-0.4-0.3-0.7-0.3
			c-0.3,0-0.6,0-0.8,0c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.7,0.4l-0.5,6.4L106.7,13.7z"
          />
        </g>
        <g className="st0">
          <path
            d="M115.7,12.2l0.1-0.8c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.7s0.5-0.4,0.9-0.4c0.4-0.1,0.8-0.1,1.2-0.1
			c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.9,0.1,1.3,0.1c0.1-0.4,0.1-0.6,0.1-0.6c0-0.2,0-0.4,0-0.6c0-0.1-0.1-0.3-0.2-0.4
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.7-0.1-2-0.2-3.7-0.2l0.2-1.4c1-0.3,2.4-0.3,4.1-0.2c1,0.1,1.8,0.4,2.3,0.9
			c0.5,0.5,0.7,1.3,0.6,2.3l-0.7,6.1l-1.8-0.2l-0.4-1c-0.1,0.1-0.2,0.2-0.5,0.3c-0.3,0.1-0.6,0.3-1.1,0.4c-0.4,0.1-0.9,0.1-1.3,0.1
			c-0.8-0.1-1.4-0.4-1.8-0.9C115.8,13.3,115.7,12.8,115.7,12.2z M118.1,12.1c0,0.3,0,0.6,0.1,0.7c0.1,0.1,0.3,0.2,0.6,0.2
			c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1
			c0.1,0,0.2-0.1,0.3-0.1l0.2-1.6l-2.2-0.1c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3s0,0.2-0.1,0.3
			L118.1,12.1z"
          />
        </g>
        <g className="st0">
          <path
            className="st4"
            d="M115.7,12.2l0.1-0.8c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.7s0.5-0.4,0.9-0.4c0.4-0.1,0.8-0.1,1.2-0.1
			c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.9,0.1,1.3,0.1c0.1-0.4,0.1-0.6,0.1-0.6c0-0.2,0-0.4,0-0.6c0-0.1-0.1-0.3-0.2-0.4
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.7-0.1-2-0.2-3.7-0.2l0.2-1.4c1-0.3,2.4-0.3,4.1-0.2c1,0.1,1.8,0.4,2.3,0.9
			c0.5,0.5,0.7,1.3,0.6,2.3l-0.7,6.1l-1.8-0.2l-0.4-1c-0.1,0.1-0.2,0.2-0.5,0.3c-0.3,0.1-0.6,0.3-1.1,0.4c-0.4,0.1-0.9,0.1-1.3,0.1
			c-0.8-0.1-1.4-0.4-1.8-0.9C115.8,13.3,115.7,12.8,115.7,12.2z M118.1,12.1c0,0.3,0,0.6,0.1,0.7c0.1,0.1,0.3,0.2,0.6,0.2
			c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1
			c0.1,0,0.2-0.1,0.3-0.1l0.2-1.6l-2.2-0.1c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3s0,0.2-0.1,0.3
			L118.1,12.1z"
          />
        </g>
        <g className="st0">
          <path
            d="M125.2,6.5l2.4,0.4l0.5,6.4c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
			c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.2,0l2.9-6.5l2.4,0.4l-4.9,11.1l-1.5,1.5l-1.7-0.3l2-4.3c-1.3-0.2-2.1-1.1-2.3-2.7L125.2,6.5z"
          />
        </g>
        <g className="st0">
          <path
            className="st5"
            d="M125.2,6.5l2.4,0.4l0.5,6.4c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.1,0.1,0.1
			c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.2,0l2.9-6.5l2.4,0.4l-4.9,11.1l-1.5,1.5l-1.7-0.3l2-4.3
			c-1.3-0.2-2.1-1.1-2.3-2.7L125.2,6.5z"
          />
        </g>
        <g className="st0">
          <path
            d="M134.5,12.7c0.2-0.9,0.4-1.6,0.7-2.2c0.3-0.6,0.6-1,1-1.2c0.4-0.3,0.9-0.4,1.4-0.5c0.5,0,1.2,0,1.9,0.2
			c1.5,0.3,2.5,0.9,2.9,1.7c0.4,0.8,0.5,2,0.1,3.8c-0.4,1.6-0.9,2.7-1.6,3.2s-1.8,0.6-3.3,0.3c-0.6-0.1-1.1-0.3-1.5-0.5
			s-0.8-0.4-1-0.7c-0.3-0.3-0.5-0.6-0.6-1c-0.1-0.4-0.2-0.8-0.1-1.3S134.3,13.3,134.5,12.7z M136.8,13.2c-0.1,0.6-0.2,1-0.2,1.3
			c0,0.3,0,0.6,0.1,0.8c0.1,0.2,0.3,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.8,0.2c0.3,0.1,0.5,0.1,0.7,0.1s0.3,0,0.5-0.1
			c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.3,0.2-0.7,0.3-1.1c0.1-0.5,0.2-0.9,0.2-1.2c0-0.3,0-0.6,0-0.8
			s-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.7-0.2c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.3,0-0.5,0
			c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.2-0.2,0.4-0.3,0.7C136.9,12.4,136.8,12.8,136.8,13.2z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M134.5,12.7c0.2-0.9,0.4-1.6,0.7-2.2c0.3-0.6,0.6-1,1-1.2c0.4-0.3,0.9-0.4,1.4-0.5c0.5,0,1.2,0,1.9,0.2
			c1.5,0.3,2.5,0.9,2.9,1.7c0.4,0.8,0.5,2,0.1,3.8c-0.4,1.6-0.9,2.7-1.6,3.2s-1.8,0.6-3.3,0.3c-0.6-0.1-1.1-0.3-1.5-0.5
			s-0.8-0.4-1-0.7c-0.3-0.3-0.5-0.6-0.6-1c-0.1-0.4-0.2-0.8-0.1-1.3S134.3,13.3,134.5,12.7z M136.8,13.2c-0.1,0.6-0.2,1-0.2,1.3
			c0,0.3,0,0.6,0.1,0.8c0.1,0.2,0.3,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.8,0.2c0.3,0.1,0.5,0.1,0.7,0.1s0.3,0,0.5-0.1
			c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.3,0.2-0.7,0.3-1.1c0.1-0.5,0.2-0.9,0.2-1.2c0-0.3,0-0.6,0-0.8
			s-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.7-0.2c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.3,0-0.5,0
			c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.2-0.2,0.4-0.3,0.7C136.9,12.4,136.8,12.8,136.8,13.2z"
          />
        </g>
        <g className="st0">
          <path
            d="M144.1,12l0.4-1.5l1.4,0.1l0.4-1.6c0.1-0.4,0.2-0.7,0.4-1c0.2-0.3,0.4-0.5,0.6-0.7c0.3-0.2,0.6-0.3,0.9-0.3
			c0.4,0,0.8,0,1.3,0.1c0.8,0.2,1.5,0.5,2.1,1l-0.4,1.3L149.7,9c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.1,0.1-0.2,0.3c-0.1,0.1-0.1,0.3-0.1,0.3c0,0.1-0.1,0.3-0.1,0.5l-0.3,1.1l2,0.5l-0.5,1.7l-2-0.5l-1.9,6.9l-2.3-0.6
			l1.9-6.9L144.1,12z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M144.1,12l0.4-1.5l1.4,0.1l0.4-1.6c0.1-0.4,0.2-0.7,0.4-1c0.2-0.3,0.4-0.5,0.6-0.7c0.3-0.2,0.6-0.3,0.9-0.3
			c0.4,0,0.8,0,1.3,0.1c0.8,0.2,1.5,0.5,2.1,1l-0.4,1.3L149.7,9c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.1,0.1-0.2,0.3c-0.1,0.1-0.1,0.3-0.1,0.3c0,0.1-0.1,0.3-0.1,0.5l-0.3,1.1l2,0.5l-0.5,1.7l-2-0.5l-1.9,6.9l-2.3-0.6
			l1.9-6.9L144.1,12z"
          />
        </g>
        <g className="st0">
          <path
            d="M149.8,17.8l1.9-5.6L154,13l-1.9,5.6c-0.3,0.8-0.1,1.2,0.6,1.4c0.3,0.1,0.6,0.2,0.8,0.2c0.3,0,0.5,0,0.7,0
			c0.2,0,0.5-0.1,0.8-0.2l2.1-6l2.2,0.8l-2.9,8.4l-1.8-0.6l-0.1-1c-0.1,0-0.3,0.1-0.6,0.1c-0.3,0.1-0.5,0.1-0.6,0.1s-0.3,0-0.5,0.1
			c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4-0.1-0.7-0.2C149.7,21.1,149.2,19.8,149.8,17.8z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M149.8,17.8l1.9-5.6L154,13l-1.9,5.6c-0.3,0.8-0.1,1.2,0.6,1.4c0.3,0.1,0.6,0.2,0.8,0.2c0.3,0,0.5,0,0.7,0
			c0.2,0,0.5-0.1,0.8-0.2l2.1-6l2.2,0.8l-2.9,8.4l-1.8-0.6l-0.1-1c-0.1,0-0.3,0.1-0.6,0.1c-0.3,0.1-0.5,0.1-0.6,0.1s-0.3,0-0.5,0.1
			c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4-0.1-0.7-0.2C149.7,21.1,149.2,19.8,149.8,17.8z"
          />
        </g>
        <g className="st0">
          <path
            d="M158.1,21.4l0.3-0.8c0.1-0.3,0.3-0.6,0.5-0.8c0.2-0.2,0.4-0.4,0.7-0.5c0.3-0.1,0.6-0.2,1-0.2s0.8,0.1,1.2,0.3
			c0.4,0.2,0.8,0.4,1.2,0.5c0.4,0.2,0.8,0.4,1.2,0.5c0.2-0.3,0.2-0.5,0.2-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.2-0.4-0.2c-0.7-0.3-1.8-0.8-3.5-1.4l0.6-1.3c1.1,0.1,2.4,0.4,3.9,1.1
			c0.9,0.4,1.6,0.9,1.9,1.6c0.3,0.6,0.3,1.4-0.2,2.4l-2.6,5.6l-1.7-0.8l0-1.1c-0.1,0.1-0.3,0.1-0.5,0.2c-0.3,0-0.7,0.1-1.1,0
			c-0.5,0-0.9-0.1-1.3-0.3c-0.7-0.4-1.2-0.8-1.4-1.4C157.9,22.6,157.9,22,158.1,21.4z M160.4,22.1c-0.1,0.3-0.2,0.5-0.1,0.7
			c0.1,0.2,0.3,0.3,0.5,0.4c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0s0.3,0,0.4,0c0.1,0,0.2,0,0.3,0
			c0.1,0,0.2,0,0.3,0l0.7-1.5l-2-0.7c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2
			c-0.1,0.1-0.1,0.2-0.1,0.3L160.4,22.1z"
          />
        </g>
        <g className="st0">
          <path
            className="st2"
            d="M158.1,21.4l0.3-0.8c0.1-0.3,0.3-0.6,0.5-0.8c0.2-0.2,0.4-0.4,0.7-0.5c0.3-0.1,0.6-0.2,1-0.2s0.8,0.1,1.2,0.3
			c0.4,0.2,0.8,0.4,1.2,0.5c0.4,0.2,0.8,0.4,1.2,0.5c0.2-0.3,0.2-0.5,0.2-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.2-0.4-0.2c-0.7-0.3-1.8-0.8-3.5-1.4l0.6-1.3c1.1,0.1,2.4,0.4,3.9,1.1
			c0.9,0.4,1.6,0.9,1.9,1.6c0.3,0.6,0.3,1.4-0.2,2.4l-2.6,5.6l-1.7-0.8l0-1.1c-0.1,0.1-0.3,0.1-0.5,0.2c-0.3,0-0.7,0.1-1.1,0
			c-0.5,0-0.9-0.1-1.3-0.3c-0.7-0.4-1.2-0.8-1.4-1.4C157.9,22.6,157.9,22,158.1,21.4z M160.4,22.1c-0.1,0.3-0.2,0.5-0.1,0.7
			c0.1,0.2,0.3,0.3,0.5,0.4c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0s0.3,0,0.4,0c0.1,0,0.2,0,0.3,0
			c0.1,0,0.2,0,0.3,0l0.7-1.5l-2-0.7c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2
			c-0.1,0.1-0.1,0.2-0.1,0.3L160.4,22.1z"
          />
        </g>
        <g className="st0">
          <path
            d="M168.3,20.8l0.7-1.1l1.5,0.3l1.6-1.9l1.7,1l-1.3,2.1l1.7,1l-0.9,1.5l-1.7-1l-1.9,3.1c-0.2,0.4-0.4,0.7-0.4,0.9
			c-0.1,0.3-0.1,0.4-0.1,0.6s0.1,0.3,0.2,0.4c0.5,0.6,0.7,0.8,0.8,0.9l-0.7,1.2l-1.8-1.1c-0.6-0.4-0.9-0.9-1-1.5
			c-0.1-0.6,0.2-1.4,0.8-2.4l2-3.4L168.3,20.8z"
          />
        </g>
        <g className="st0">
          <path
            className="st6"
            d="M168.3,20.8l0.7-1.1l1.5,0.3l1.6-1.9l1.7,1l-1.3,2.1l1.7,1l-0.9,1.5l-1.7-1l-1.9,3.1
			c-0.2,0.4-0.4,0.7-0.4,0.9c-0.1,0.3-0.1,0.4-0.1,0.6s0.1,0.3,0.2,0.4c0.5,0.6,0.7,0.8,0.8,0.9l-0.7,1.2l-1.8-1.1
			c-0.6-0.4-0.9-0.9-1-1.5c-0.1-0.6,0.2-1.4,0.8-2.4l2-3.4L168.3,20.8z"
          />
        </g>
        <g className="st0">
          <path
            d="M171.4,28.3l0.6-0.6c0.2-0.3,0.5-0.5,0.7-0.6s0.5-0.2,0.8-0.3c0.3,0,0.6,0,1,0.1s0.7,0.4,1,0.7c0.3,0.3,0.6,0.6,1,0.9
			c0.3,0.3,0.6,0.6,1,0.9c0.3-0.3,0.4-0.4,0.4-0.4c0.2-0.2,0.3-0.3,0.3-0.5c0.1-0.1,0.1-0.3,0-0.4c0-0.1-0.1-0.3-0.2-0.4
			c-0.1-0.1-0.2-0.2-0.4-0.4c-0.5-0.5-1.5-1.3-2.9-2.4l1-1.1c1,0.4,2.2,1.1,3.4,2.3c0.8,0.7,1.2,1.4,1.4,2.1
			c0.1,0.7-0.2,1.4-0.9,2.2l-4.2,4.7l-1.4-1.2l0.3-1.1c-0.1,0-0.3,0-0.6,0c-0.3,0-0.7-0.1-1.1-0.3c-0.4-0.2-0.8-0.4-1.1-0.7
			c-0.6-0.6-0.9-1.2-0.9-1.8C170.8,29.3,171,28.7,171.4,28.3z M173.4,29.6c-0.2,0.3-0.3,0.5-0.3,0.6c0,0.2,0.2,0.4,0.4,0.5
			c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
			c0.1,0,0.2,0,0.3,0.1l1.1-1.2l-1.7-1.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1s-0.1,0.1-0.2,0.2
			L173.4,29.6z"
          />
        </g>
        <g className="st0">
          <path
            className="st7"
            d="M171.4,28.3l0.6-0.6c0.2-0.3,0.5-0.5,0.7-0.6s0.5-0.2,0.8-0.3c0.3,0,0.6,0,1,0.1s0.7,0.4,1,0.7
			c0.3,0.3,0.6,0.6,1,0.9c0.3,0.3,0.6,0.6,1,0.9c0.3-0.3,0.4-0.4,0.4-0.4c0.2-0.2,0.3-0.3,0.3-0.5c0.1-0.1,0.1-0.3,0-0.4
			c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.4c-0.5-0.5-1.5-1.3-2.9-2.4l1-1.1c1,0.4,2.2,1.1,3.4,2.3c0.8,0.7,1.2,1.4,1.4,2.1
			c0.1,0.7-0.2,1.4-0.9,2.2l-4.2,4.7l-1.4-1.2l0.3-1.1c-0.1,0-0.3,0-0.6,0c-0.3,0-0.7-0.1-1.1-0.3c-0.4-0.2-0.8-0.4-1.1-0.7
			c-0.6-0.6-0.9-1.2-0.9-1.8C170.8,29.3,171,28.7,171.4,28.3z M173.4,29.6c-0.2,0.3-0.3,0.5-0.3,0.6c0,0.2,0.2,0.4,0.4,0.5
			c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
			c0.1,0,0.2,0,0.3,0.1l1.1-1.2l-1.7-1.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1s-0.1,0.1-0.2,0.2
			L173.4,29.6z"
          />
        </g>
      </g>
    </svg>
  ),
  sound: (
    <svg className="sound-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.62 12.07">
      <title>sound</title>
      <g className="cls-2">
        <g className="cls-3">
          <path
            className="cls-4"
            d="M9.1,0A6,6,0,0,0,4,2.8H1.7A1.7,1.7,0,0,0,0,4.5V7.57a1.7,1.7,0,0,0,1.7,1.7H4a6,6,0,0,0,5.1,2.8.44.44,0,0,0,.43-.44V.44A.44.44,0,0,0,9.1,0"
            transform="translate(0)"
          />
          <path
            className="cls-4"
            d="M11.24,2.67a.44.44,0,0,0-.61.11.43.43,0,0,0,.11.6,3.23,3.23,0,0,1,0,5.31.44.44,0,0,0-.11.61.44.44,0,0,0,.36.19.49.49,0,0,0,.25-.08,4.11,4.11,0,0,0,0-6.74"
            transform="translate(0)"
          />
          <path
            className="cls-4"
            d="M12.92.87a.44.44,0,0,0-.61.11.43.43,0,0,0,.11.6,5.42,5.42,0,0,1,0,8.91.43.43,0,0,0-.11.6.41.41,0,0,0,.36.19.46.46,0,0,0,.25-.08,6.29,6.29,0,0,0,0-10.33"
            transform="translate(0)"
          />
        </g>
      </g>
    </svg>
  ),
  vectorSmart: (
    <svg className="music-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.82 15.62">
      <title>Vector Smart Object16</title>
      <g className="cls-2">
        <g className="cls-3">
          <path
            className="cls-4"
            d="M9.94,9.93a6.39,6.39,0,0,0,.87-2.85,4,4,0,0,0-.42-2A5.56,5.56,0,0,0,8.23,3a6.23,6.23,0,0,1-1.6-1.35l-.07-.1A2.82,2.82,0,0,1,6,.52.6.6,0,0,0,5.36,0a.59.59,0,0,0-.55.6V11.31a3.47,3.47,0,0,0-1.8-.5c-1.66,0-3,1.08-3,2.4a2.76,2.76,0,0,0,3,2.41,2.76,2.76,0,0,0,3-2.41v-7A4.32,4.32,0,0,1,8.79,9.49a3.88,3.88,0,0,1-.23.33.6.6,0,0,0,.9.79A4.61,4.61,0,0,0,9.9,10l0-.06"
          />
        </g>
      </g>
    </svg>
  ),
  playDemo: (
    <svg
      className="play-demo__leaders-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.22 16.92">
      <polygon
        className="cls-1"
        points="13.11 10.67 10.11 1.63 7.11 10.67 0 6.23 4.37 16.92 10.11 16.92 15.85 16.92 20.22 6.23 13.11 10.67"
      />
      <path className="cls-1" d="M12.47,2.36A2.36,2.36,0,1,1,10.11,0a2.36,2.36,0,0,1,2.36,2.36" />
    </svg>
  ),
  howToPlay: (
    <svg
      className="how-to-play__clocirclese-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14">
      <path d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z" />
    </svg>
  ),
  loremIpsum: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
            Aldus PageMaker including versions of Lorem Ipsum.`,
  prevGameClose: (
    <svg className="game-popup-title-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <path d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z" />
    </svg>
  ),
  liveGame: (
    <svg
      className="my-bets__head-id-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.45 15.07">
      <title>game</title>
      <path
        className="cls-1"
        d="M9.88,8.72l-7.39,6A1.53,1.53,0,0,1,0,13.55v-12A1.53,1.53,0,0,1,2.49.34l7.39,6a1.53,1.53,0,0,1,0,2.37"
      />
    </svg>
  ),
};

export const BurgerTabsName = {
  0: 'Provable Fairness',
  1: 'Game Rules',
  2: 'Game Limits',
};

export const SETTING_ICONS = {
  SOUND: 'sound',
  MUSIC: 'music',
  ROCKET: 'rocket',
  FULLSCREEN: 'resize',
};

const icon1 = (
  <svg className="sound-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.62 12.07">
    <title>sound</title>
    <g className="cls-2">
      <g className="cls-3">
        <path
          className="cls-4"
          d="M9.1,0A6,6,0,0,0,4,2.8H1.7A1.7,1.7,0,0,0,0,4.5V7.57a1.7,1.7,0,0,0,1.7,1.7H4a6,6,0,0,0,5.1,2.8.44.44,0,0,0,.43-.44V.44A.44.44,0,0,0,9.1,0"
          transform="translate(0)"
        />
        <path
          className="cls-4"
          d="M11.24,2.67a.44.44,0,0,0-.61.11.43.43,0,0,0,.11.6,3.23,3.23,0,0,1,0,5.31.44.44,0,0,0-.11.61.44.44,0,0,0,.36.19.49.49,0,0,0,.25-.08,4.11,4.11,0,0,0,0-6.74"
          transform="translate(0)"
        />
        <path
          className="cls-4"
          d="M12.92.87a.44.44,0,0,0-.61.11.43.43,0,0,0,.11.6,5.42,5.42,0,0,1,0,8.91.43.43,0,0,0-.11.6.41.41,0,0,0,.36.19.46.46,0,0,0,.25-.08,6.29,6.29,0,0,0,0-10.33"
          transform="translate(0)"
        />
      </g>
    </g>
  </svg>
);

const icon2 = (
  <svg className="music-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.82 15.62">
    <title>music</title>
    <g className="cls-2">
      <g className="cls-3">
        <path
          className="cls-4"
          d="M9.94,9.93a6.39,6.39,0,0,0,.87-2.85,4,4,0,0,0-.42-2A5.56,5.56,0,0,0,8.23,3a6.23,6.23,0,0,1-1.6-1.35l-.07-.1A2.82,2.82,0,0,1,6,.52.6.6,0,0,0,5.36,0a.59.59,0,0,0-.55.6V11.31a3.47,3.47,0,0,0-1.8-.5c-1.66,0-3,1.08-3,2.4a2.76,2.76,0,0,0,3,2.41,2.76,2.76,0,0,0,3-2.41v-7A4.32,4.32,0,0,1,8.79,9.49a3.88,3.88,0,0,1-.23.33.6.6,0,0,0,.9.79A4.61,4.61,0,0,0,9.9,10l0-.06"
        />
      </g>
    </g>
  </svg>
);

const icon3 = (
  <svg className="rocket-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.86 15.86">
    <title>rocket animation</title>
    <g className="cls-2">
      <g className="cls-3">
        <path
          className="cls-4"
          d="M2.32,10.16a.47.47,0,0,0,.08.54l.12.11c.32-.38.71-.81,1.17-1.27l.1-.08-.73-.73c-.43.77-.67,1.29-.74,1.43"
          transform="translate(0)"
        />
        <path
          className="cls-4"
          d="M6.32,12.17c-.47.46-.89.85-1.27,1.17l.15.16a.45.45,0,0,0,.54.08c.24-.12.62-.3,1.42-.75l-.76-.76-.08.1"
          transform="translate(0)"
        />
        <path
          className="cls-4"
          d="M6.07,9.79c-.33-.34-1.28,0-1.72.41a12.21,12.21,0,0,0-2.46,3.15.47.47,0,0,0,.09.53.46.46,0,0,0,.53.09,12.24,12.24,0,0,0,3.15-2.46c.49-.49.73-1.4.41-1.72Z"
          transform="translate(0)"
        />
        <path
          className="cls-4"
          d="M1.45,6.19.14,7.5a.47.47,0,0,0-.08.56.48.48,0,0,0,.52.22,3.8,3.8,0,0,1,1.76-.16A24.87,24.87,0,0,1,4.26,5.18c-.39-.25-1.69-.11-2.81,1"
          transform="translate(0)"
        />
        <path
          className="cls-4"
          d="M7.87,15.81a.44.44,0,0,0,.53-.09l1.32-1.31a2.93,2.93,0,0,0,1-2.75,26.9,26.9,0,0,1-2.93,1.91,5.76,5.76,0,0,1-.14,1.76.46.46,0,0,0,.26.48"
          transform="translate(0)"
        />
        <path
          className="cls-4"
          d="M15.83.45A.46.46,0,0,0,15.4,0,9.34,9.34,0,0,0,12,.42a3.92,3.92,0,0,0,1.16,2.27,3.86,3.86,0,0,0,2.31,1.15A9.62,9.62,0,0,0,15.83.45"
          transform="translate(0)"
        />
        <path
          className="cls-4"
          d="M12.51,3.35A5,5,0,0,1,11.11.74,16.89,16.89,0,0,0,3.54,7.9l1,1a2,2,0,0,1,2.14.18,2,2,0,0,1,.18,2.14L8,12.35c2.71-1.63,5.85-4.19,7.19-7.6a4.91,4.91,0,0,1-2.67-1.4"
          transform="translate(0)"
        />
      </g>
    </g>
  </svg>
);

const icon4 = (
  <svg className="resize-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.06 13.89">
    <title>full screen</title>
    <g className="cls-2">
      <g className="cls-3">
        <path
          className="cls-4"
          d="M.06,4.75,0,.4A.4.4,0,0,1,.4,0L4.75.06A.4.4,0,0,1,5,.74L3.82,2l2,2L4,5.83l-2-2L.73,5a.4.4,0,0,1-.67-.28"
          transform="translate(0)"
        />
        <path
          className="cls-4"
          d="M12.11,3.84l1.22,1.21A.39.39,0,0,0,14,4.78L14.06.43a.4.4,0,0,0-.4-.4L9.31.09A.39.39,0,0,0,9,.76L10.24,2l-2,2L10.1,5.85Z"
          transform="translate(0)"
        />
        <path
          className="cls-4"
          d="M9,13.16a.4.4,0,0,0,.28.67l4.35.06a.39.39,0,0,0,.4-.4L14,9.14a.4.4,0,0,0-.68-.27l-1.21,1.21-2-2L8.23,9.94l2,2Z"
          transform="translate(0)"
        />
        <path
          className="cls-4"
          d="M.4,13.86l4.35-.06A.4.4,0,0,0,5,13.13L3.82,11.92l2-2L4,8l-2,2L.73,8.84a.39.39,0,0,0-.67.27L0,13.47a.39.39,0,0,0,.4.39"
          transform="translate(0)"
        />
      </g>
    </g>
  </svg>
);

export const SETTINGS = [
  { name: SETTING_ICONS.SOUND, svg: icon1 },
  { name: SETTING_ICONS.MUSIC, svg: icon2 },
  { name: SETTING_ICONS.ROCKET, svg: icon3 },
  { name: SETTING_ICONS.FULLSCREEN, svg: icon4 },
];

export const INCREMENT_VALUE = 10;

export const CONSOLE_TYPES = {
  FIRST: 'first',
  SECOND: 'second',
};

export const POPUPS = {
  ERRORS: 'errors',
  AVATARS: 'avatars',
  VALIDITY: 'validity',
  PROBABLY: 'probably',
};
export const AvatarIcons = {
  0: {
    name: 'Alien',
  },
  1: {
    name: 'Good player',
  },
  2: {
    name: 'Stars of Mars',
  },
  3: {
    name: 'Rocketman',
  },
  4: {
    name: 'Moony',
  },
  5: {
    name: 'Captain',
  },
  6: {
    name: 'Alien',
  },
  7: {
    name: 'Good player',
  },
  8: {
    name: 'Stars of Mars',
  },
  9: {
    name: 'Rocketman',
  },
  10: {
    name: 'Moony',
  },
  11: {
    name: 'Captain',
  },
};
