import React from 'react';
import { SETTING_ICONS } from '../../../constants/game';

const SettingIcon = ({
  svg, name, handleClick, isActive,
}) => (
  <button
    type='button'
    className={`settings ${!isActive ? `settings-${name} active` : ''}`}
    onClick={() => handleClick(name)}
  >
    {
      name === SETTING_ICONS.FULLSCREEN && isActive
        ? <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 15 14'
          style={{
            fill: 'rgba(var(--rocket-gray2), 1)', opacity: '0.7', width: '15px', height: '14px',
          }}
        >
          <path
            d='M6.15 1.09l.07 4.38a.42.42 0 01-.43.41l-4.64-.07a.4.4 0 01-.29-.68l1.29-1.22L0 1.88 2 0l2.15 2L5.44.81a.42.42 0 01.71.28M10.86 2.06L9.57.84a.42.42 0 00-.72.27L8.78 5.5a.41.41 0 00.43.4l4.64-.06a.39.39 0 00.29-.68l-1.29-1.22 2.15-2L13 0zM14.14 8.87a.39.39 0 00-.3-.67L9.2 8.13a.41.41 0 00-.42.4l.07 4.39a.42.42 0 00.71.27L10.85 12 13 14l2-1.88-2.15-2zM5.79 8.1l-4.64.06a.39.39 0 00-.29.68l1.29 1.22L0 12.09 2 14l2.15-2 1.29 1.22a.42.42 0 00.72-.27l.06-4.45a.41.41 0 00-.43-.4'
            className='cls-1'
          />
        </svg> : svg
    }
  </button>
);
export default SettingIcon;
