import en from './jsons/en.json';
import sw from './jsons/sw.json';
import am from './jsons/am.json';

const languages = {
  en,
  sw,
  am,
};

const getActiveLanguage = (lang) => languages[lang] || languages['en'];

export default getActiveLanguage;
