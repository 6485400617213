import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BurgerMenu from './BurgerMenu';
import SettingIcon from './SettingIcon';
import { SETTINGS, SETTING_ICONS } from '../../../constants/game';
// eslint-disable-next-line import/named
import { setSoundData, setOptions } from '../../../redux/actions/gameActions';
import { pushToStorage } from '../../../helpers/storageMenagement';

const { SOUND, MUSIC, ROCKET, FULLSCREEN } = SETTING_ICONS;

// eslint-disable-next-line no-shadow
function MainHeadRight({ setSoundData, setOptions, activeOptions }) {
  const [isActive, setIsActive] = useState(false);
  const [activeBurgerTab, setActiveBurgerTab] = useState(0);
  const handleChangeOptions = (name) => {
    if (name !== FULLSCREEN) {
      setOptions({ ...activeOptions, [name]: !activeOptions[name] });
      pushToStorage('options', JSON.stringify({ ...activeOptions, [name]: !activeOptions[name] }));
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', (e) => {
      setOptions({ ...activeOptions, [FULLSCREEN]: !!document.fullscreenElement });
      pushToStorage(
        'options',
        JSON.stringify({ ...activeOptions, [FULLSCREEN]: !!document.fullscreenElement }),
      );
    });
  }, [activeOptions]);

  const onFullScreenClick = () => {
    const element = document.getElementById('root');
    !document.fullscreenElement ? element.requestFullscreen() : document.exitFullscreen();
  };

  const handleClick = (name) => {
    handleChangeOptions(name);
    name === FULLSCREEN && onFullScreenClick();
    if (name === 'music') {
      if (activeOptions[MUSIC]) {
        localStorage.setItem('music', 0);
        return setSoundData({ gameMusic: true });
      }
      localStorage.setItem('music', 1);
      return setSoundData({ gameMusic: false });
    }
    if (name === 'sound') {
      if (activeOptions[SOUND]) {
        localStorage.setItem('sound', 0);
        return setSoundData({ allSound: true });
      }
      localStorage.setItem('sound', 1);
      return setSoundData({ allSound: false });
    }
    if (name === 'rocket') {
      if (activeOptions[ROCKET]) {
        localStorage.setItem('rocket', 0);
        return setSoundData({ allSound: true });
      }
      localStorage.setItem('rocket', 1);
      return setSoundData({ allSound: false });
    }
  };

  return (
    <div className="main__head-right">
      {SETTINGS.map((setting) => {
        const { name, svg } = setting;
        return (
          <SettingIcon
            svg={svg}
            name={name}
            isActive={activeOptions[name]}
            handleClick={handleClick}
            key={name}
          />
        );
      })}
      <BurgerMenu
        isActive={isActive}
        handleIsActive={handleIsActive}
        handleActiveBurgerTab={handleActiveBurgerTab}
        activeBurgerTab={activeBurgerTab}
      />
    </div>
  );
  function handleIsActive() {
    setIsActive(!isActive);
  }
  function handleActiveBurgerTab(id) {
    setActiveBurgerTab(id);
  }
}
const mapStateToProps = ({ activeOptionsReducer }) => ({ activeOptions: activeOptionsReducer });

const mapDispatchToProps = { setSoundData, setOptions };

export default connect(mapStateToProps, mapDispatchToProps)(MainHeadRight);
